export * from './layout';

export const ORG_ID = {
    VN2: 5,
    VN3: 6,
    VN4: 15,
    VNID: 16,
    TH1: 10,
    TH2: 12,
    ID1: 9,
    ID2: 8,
    ID3: 18,
    PH: 14,
    MY1: 11,
    MY2: 17,
    IN1: 19,
}