const users = {
    'TITLE': 'User Management',
    'SEARCH': 'Search For Users',
    'IMPORT': 'Import',
    'EXPORT': 'Export',
    'ADD': 'Add user',
    'FULL_NAME': 'Fullname & ID',
    'USER_NAME': 'Username',
    'PHONE': 'Phone',
    'GROUP': 'Group',
    'TEAM': 'Team',
    'PASSWORD': 'Password Expiry',
    'SKILL': 'Skill level',
    'FULLNAME': 'Full name',
    'STATUS': 'Status',
    'USER_ROLE': 'User Role',
    'CLOSE': 'Close',
    'APPLY': 'Apply Filter',
    'CLEAR': 'Clear Filter',
    'TEMPLATE': 'Template',
    'SUBMIT': 'Submit',
    'LAST_UPDATE': 'Last updated date',
    'USER_TYPE': 'User Type',
    'AGENT_TYPE': 'Agent Type',
    'EMAIL': 'Email',
    'PASSWORD_DIALOG': 'Password',
    'FEEDBACK_1': 'Must contain at least one lowercase letter',
    'FEEDBACK_2': 'Must contain at least one uppercase letter',
    'FEEDBACK_3': 'Must contain at least one number',
    'FEEDBACK_4': 'Must contain at least one special character',
    'FEEDBACK_5': 'Must not contain any space',
    'FEEDBACK_6': 'Must contain at least 8 characters',
    'DOMAIN': 'Domain',
    'BIRTHDAY': 'Birthday',
    'PASS_EX': 'Password Expiry Date',
    'NEW_PASS': 'New Password',
    'CONFIRM': 'Confirm New Password',
    'CANCEL': 'Cancel',
    'SAVE': 'Save',
    'ENTER_PASS': 'Enter password',
    'ENTER_USER': 'Enter username',
    'ENTER_BIRTH': 'Enter birthday',
    'ENTER_NAME': 'Enter fullname',
    'ENTER_PHONE': 'Enter phone',
    'ALERT_USERNAME': 'Username must start with',
};

export default users;