const users = {
    'TITLE': 'Quản lý người dùng',
    'SEARCH': 'Tìm kiếm Người dùng',
    'IMPORT': 'Nhập',
    'EXPORT': 'Xuất',
    'ADD': 'Thêm người dùng',
    'FULL_NAME': 'Họ tên và ID',
    'USER_NAME': 'Tên người dùng',
    'PHONE': 'Điện thoại',
    'GROUP': 'Nhóm',
    'TEAM': 'Đội',
    'PASSWORD': 'Hết hạn mật khẩu',
    'SKILL': 'Trình độ kỹ năng',
    'FULLNAME': 'Họ và Tên',
    'STATUS': 'Trạng thái',
    'USER_ROLE': 'Vai trò Người dùng',
    'CLOSE': 'Đóng',
    'APPLY': 'Áp dụng Bộ lọc',
    'CLEAR': 'Xóa bộ lọc',
    'TEMPLATE': 'Mẫu',
    'SUBMIT': 'Gửi',
    'LAST_UPDATE': 'Ngày cập nhật lần cuối',
    'USER_TYPE': 'Kiểu người dùng',
    'AGENT_TYPE': 'Kiểu agent',
    'EMAIL': 'Email',
    'PASSWORD_DIALOG': 'Mật khẩu',
    'FEEDBACK_1': 'Phải chứa ít nhất một chữ thường',
    'FEEDBACK_2': 'Phải chứa ít nhất một chữ cái viết hoa',
    'FEEDBACK_3': 'Phải chứa ít nhất một số',
    'FEEDBACK_4': 'Phải chứa ít nhất một ký tự đặc biệt',
    'FEEDBACK_5': 'Không được chứa bất kỳ khoảng trắng nào',
    'FEEDBACK_6': 'Phải chứa ít nhất 8 ký tự',
    'DOMAIN': 'Tên miền',
    'BIRTHDAY': 'Sinh nhật',
    'PASS_EX': 'Ngày hết hạn mật khẩu',
    'NEW_PASS': 'Mật khẩu mới',
    'CONFIRM': 'Xác nhận Mật khẩu Mới',
    'CANCEL': 'Hủy bỏ',
    'SAVE': 'Lưu',
    'ENTER_PASS': 'Nhập mật khẩu',
    'ENTER_USER': 'Nhập tên người dùng',
    'ENTER_BIRTH': 'Nhập ngày sinh',
    'ENTER_NAME': 'Nhập họ và tên',
    'ENTER_PHONE': 'Nhập số điện thoại',
    'ALERT_USERNAME': 'Tên người dùng phải bắt đầu bằng',
};

export default users;